import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithConfig from 'Auth0ProviderWithConfig';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main = () => {
  console.log('entra aquiiiiiii');

  return (
    <Provider store={configureStore()}>
      <Auth0ProviderWithConfig>
        <Suspense fallback={<div className="loading" />}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
          {/* <Toaster position="top-right" reverseOrder={false} /> */}
          <Toaster />
        </Suspense>
      </Auth0ProviderWithConfig>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
